<template>
  <teleport v-if="url" to="body">
    <div class="xone-url" @click="url = null">
      <div>
        <iframe :src="url"></iframe>
      </div>
    </div>
  </teleport>
</template>

<script>
import { ref } from "vue";
import xoneUI, { setOpenUrlCallback } from "../../composables/XoneUI";
export default {
  setup() {
    const url = ref();

    setOpenUrlCallback((Url) => {
      url.value = Url;
    });

    return { url };
  },
};
</script>

<style scoped>
.xone-url {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s zoomIn 0.3s;
}

.xone-url > div {
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
  background-color: white;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
