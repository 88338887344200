import { normalizeWebColor } from "./helperFunctions/ColorHelper";

/**
 * XonePDF
 */
class XonePDF {
  /** @type {number} */
  _width;

  /** @type {number} */
  _height;

  /** @type {HTMLDivElement} */
  _template;

  /** @type {HTMLDivElement} */
  _templateAbsolute;

  /** @type {HTMLDivElement} */
  _currentTable;

  /** @type {Array<Array<HTMLDivElement>>} */
  _divs;

  permissions() {}
  setEncryption() {}
  open() {}
  close() {
    this.clearPDF();
  }
  beginHeader() {}
  endHeader() {}
  beginFooter() {}
  endFooter() {}
  getCurrentPage() {}

  getVerticalPosition() {
    this._template.style.display = null;
    const value =
      this._template.lastChild?.getBoundingClientRect().y +
      this._template.lastChild?.getBoundingClientRect().height;
    this._template.style.display = "none";
    return value || 0;
  }

  getHorizontalPosition() {
    this._template.style.display = null;
    const value =
      this._template.lastChild?.getBoundingClientRect().x +
      this._template.lastChild?.getBoundingClientRect().width;
    this._template.style.display = "none";
    return value || 0;
  }

  /**
   * launchPDF
   */
  launchPDF() {
    this._template.style.display = null;
    const newWindow = window.open("", "", "");
    newWindow.document.write("<html>");
    newWindow.document.write(`
    <head>
        <title>${this._name}</title>
        <style>
        * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }
        
        @font-face {
          font-family: "Roboto";
          src: url("/fonts/Roboto-Medium.ttf");
        }
        body {
          font-family: "Roboto", Helvetica, Arial, sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        </style>
      </head>
      <body>
    `);

    const container = document.createElement("div");
    container.style.display = "flex";
    container.style.flexDirection = "column";
    container.style.alignItems = "center";
    container.appendChild(this._templateAbsolute);
    container.appendChild(this._template);

    newWindow.document.write(container.outerHTML);
    newWindow.document.write("</body>\n</html>");
    newWindow.document.close();
    newWindow.focus();
    this.clearPDF();

    setTimeout(() => newWindow.print(), 500);
  }

  /**
   * Create document
   * @param {string} name
   * @param {number} [width]
   * @param {number} [height]
   */
  create(name, width = 595, height = 842) {
    this._name = name;

    this._width = width;
    this._height = height;
    // Absolute Template Element
    const elementAbsolute = document.createElement("div");
    elementAbsolute.style.width = width + "px";
    elementAbsolute.style.height = height + "px";
    elementAbsolute.style.position = "absolute";
    this._templateAbsolute = elementAbsolute;
    // Element
    const element = elementAbsolute.cloneNode(true);
    element.style.position = "relative";
    element.style.display = "flex";
    element.style.flexDirection = "column";
    element.style.width = `${Number(width) - 160}px`;
    element.style.height = `${Number(height) - 40}px`;
    element.style.margin = "20px 80px";
    element.style.display = "none";
    element.setAttribute("class", "XonePdf");
    // Clear old PDF
    this.clearPDF();
    // Add new PDF
    document.querySelector("body").prepend(element);

    this._template = element;
  }

  clearPDF() {
    document
      .querySelectorAll(".XonePdf")
      .forEach((e) => document.querySelector("body").removeChild(e));
  }

  setFont(font) {
    this._font = font;
  }

  setFontStyle(fontStyle) {
    this._fontStyle = fontStyle;
  }

  setAlignment(alignment) {
    this._alignment = alignment;
  }

  setFontSize(fontSize) {
    this._fontSize = fontSize;
  }

  setFontColor(fontColor) {
    this._fontColor = fontColor;
  }

  createTable(columns) {
    this._currentTableNewLine = false;
    this._currentTableColumns = columns;

    // Create table
    const tableElement = document.createElement("div");
    tableElement.style.display = "flex";
    tableElement.style.flexDirection = "column";
    this._currentTable = tableElement;

    // Create Rows
    this.createRow();

    // Create Rows Columns
    this.createColumns(this._currentTable.lastChild, columns);

    this._cellBorder = "all";
  }

  createRow() {
    // Create row
    const rowElement = document.createElement("div");
    rowElement.style.display = "flex";
    rowElement.style.flexDirection = "row";
    rowElement.style.flexGrow = "1";
    this._currentTable.appendChild(rowElement);
  }

  createColumns(rowElement, columns) {
    // Create columns
    this._divs = [...Array(Number(columns))].map(() => {
      // Create column
      const columnElement = document.createElement("div");
      columnElement.style.flexGrow = "1";
      rowElement.appendChild(columnElement);
      return columnElement;
    });
  }

  addTable() {
    this._template.appendChild(this._currentTable);
    this._currentTable = null;
  }

  addTableSetXY(x, y) {
    if (!isNaN(Number(x))) x = x + "px";
    // if (!isNaN(Number(y))) y = y + "px";
    this._currentTable.style.position = "absolute";
    this._currentTable.style.left = x;
    // this._currentTable.style.bottom = y;
    this._currentTable.style.top = `${this._height - Number(y)}px`;
    this._templateAbsolute.appendChild(this._currentTable);
    this._currentTable = null;
  }

  addRectangle(x, y, width, height, lineSize, backgroundColor, borderColor) {
    lineSize = Number(lineSize) / 2;
    if (width === 0) width = 0.1;
    if (height === 0) height = 0.1;
    if (lineSize === 0) lineSize = 0.1;
    if (!isNaN(Number(width))) width += "px";
    if (!isNaN(Number(height))) height += "px";
    if (!isNaN(Number(lineSize))) lineSize += "px";
    const element = document.createElement("div");
    element.style.position = "absolute";
    element.style.left = x + "px";
    // TODO: Esto no tiene logica, addRectangle toma la coordenada 0 desde abajo, y pinta el control para arriba,
    // el resto de controles se pintan para abajo....mejor no razonarlo y hacerlo igual de mal que esta hecho en android,
    // hay que hacer un master para entender esto
    element.style.bottom = y + "px";
    // element.style.top = `${this._height - Number(y)}px`;
    element.style.width = width;
    element.style.height = height;
    element.style.border = `${lineSize} solid ${normalizeWebColor(
      borderColor
    )}`;
    element.style.background = normalizeWebColor(backgroundColor);
    this._templateAbsolute.appendChild(element);
  }

  newLine() {
    this._template.append(document.createElement("hr"));
  }
  newPage() {}

  fromHtml(html) {
    this._template.innerHTML = html;
  }

  setTableWidth(width) {
    // TODO: seguimos con la ilogica, este width es en pixeles y ademas define el width minimo, de ahi puede crecer, es tremendamente logico
    if (!isNaN(Number(width))) width = width + "px";
    this._currentTable.style.minWidth = width;
    this._currentTable.style.minWidth = width;
  }

  setTableCellWidths(...widths) {
    // TODO: seguimos con la ilogica, estos width son en porcentaje del contol contenedor....todo muy normal, igual que el metodo de arriba
    // yo no se que se habian fumado cuando han programado esto asi xD
    widths.forEach((width, index) => {
      if (!isNaN(Number(width))) width = width + "%";
      this._divs[index].style.width = width;
    });
  }

  setCellBorder(cellBorder) {
    this._cellBorder = cellBorder;
    const element =
      this._divs?.length !== 0
        ? this._divs[0]
        : this._template?.lastChild || this._templateAbsolute.lastChild;

    if (!element) return;

    const borderStyle = "1px solid black";

    switch (cellBorder) {
      case "none":
        return (element.style.border = "none");
      case "all":
        return (element.style.border = borderStyle);
      case "top":
        return (element.style.borderTop = borderStyle);
      case "right":
        return (element.style.borderRight = borderStyle);
      case "bottom":
        return (element.style.borderBottom = borderStyle);
      case "left":
        return (element.style.borderLeft = borderStyle);
    }
  }

  addCellText(text) {
    if (this._currentTable) {
      if (this._divs.length === 0) {
        this._currentTableNewLine = true;
        this.createRow();
        this.createColumns(
          this._currentTable.lastChild,
          this._currentTableColumns
        );
        this._currentTable.firstChild.childNodes.forEach((e, index) => {
          this._divs[index].style.border = e.style.border;
          this._divs[index].style.width = e.style.width;
        });
      }
      // if (!this._currentTableNewLine)
      this.setCellBorder(this._cellBorder);
      this._divs[0].appendChild(this.createText(text));
      this._divs.shift();
    } else this._template.appendChild(this.createText(text));
  }

  addCellImage(imagePath, width, height) {
    if (this._currentTable) {
      if (this._divs.length === 0) {
        this._currentTableNewLine = true;
        this.createRow();
        this.createColumns(
          this._currentTable.lastChild,
          this._currentTableColumns
        );
        this._currentTable.firstChild.childNodes.forEach((e, index) => {
          this._divs[index].style.border = e.style.border;
        });
      }
      // if (!this._currentTableNewLine)
      this.setCellBorder(this._cellBorder);
      this._divs[0].appendChild(this.createImage(imagePath, width, height));
      this._divs.shift();
    } else
      this._template.appendChild(this.createImage(imagePath, width, height));
  }

  addImageSetXY(imagePath, width, height, x, y) {
    if (!isNaN(Number(x))) x = x + "px";
    // if (!isNaN(Number(y))) y = y + "px";
    const imageElement = this.createImage(imagePath, width, height);
    imageElement.style.position = "absolute";
    imageElement.style.left = x;
    // imageElement.style.bottom = y;
    imageElement.style.top = `${this._height - Number(y)}px`;
    this._templateAbsolute.appendChild(imageElement);
  }

  addTextSetX(text, width) {
    const textElement = this.createText(text);
    textElement.style.marginLeft = width + "px";
    this._template.appendChild(textElement);
  }

  addTextSetXY(text, x, y) {
    if (!isNaN(Number(x))) x = x + "px";
    // if (!isNaN(Number(y))) y = y + "px";
    const textElement = this.createText(text);
    textElement.style.position = "absolute";
    textElement.style.left = x;
    // textElement.style.bottom = y;
    textElement.style.top = `${this._height - Number(y)}px`;
    this._templateAbsolute.appendChild(textElement);
  }

  addCheckboxSetXY(text, x, y, width, value) {
    if (!isNaN(Number(x))) x = x + "px";
    const checkElement = document.createElement("input");
    checkElement.innerText = text;
    checkElement.value = value.toString() === "true";
    checkElement.setAttribute("type", "checkbox");
    checkElement.style.width = width + "px";
    checkElement.style.position = "absolute";
    checkElement.style.left = x;
    // textElement.style.bottom = y;
    checkElement.style.top = `${this._height - Number(y)}px`;
    this._templateAbsolute.appendChild(checkElement);
  }

  addCheckbox(text, width, value) {
    if (!isNaN(Number(x))) x = x + "px";
    const checkElement = document.createElement("input");
    checkElement.innerText = text;
    checkElement.value = value.toString() === "true";
    checkElement.setAttribute("type", "checkbox");
    checkElement.style.width = width + "px";
    // textElement.style.bottom = y;
    this._template.appendChild(checkElement);
  }

  addText(text) {
    this._template.appendChild(this.createText(text));
  }

  addTextLine(text) {
    this.addText(text);
  }

  /**
   *
   * @param {*} text
   * @returns {HTMLElement}
   */
  createText(text) {
    /** @type {HTMLLabelElement} */
    const textElement = document.createElement("p");
    textElement.innerText = text;
    // set attributes
    if (this._fontSize) textElement.style.fontSize = this._fontSize + "px";
    if (this._fontColor)
      textElement.style.color = normalizeWebColor(this._fontColor);

    const element =
      this._divs?.length !== 0
        ? this._divs[0]
        : this._template.lastChild || this._templateAbsolute.lastChild;

    if (this._alignment.includes("center")) element.style.textAlign = "center";
    if (this._alignment.includes("right")) element.style.textAlign = "right";
    if (this._fontStyle.includes("bold")) element.style.fontWeight = "bold";

    return textElement;
  }

  /**
   *
   * @param {*} imagePath
   * @param {*} width
   * @param {*} height
   * @returns {HTMLElement}
   */
  createImage(imagePath, width, height) {
    /** @type {HTMLElement} */
    const imageElement = document.createElement("image");
    imageElement.setAttribute("src", imagePath.replace("./", ""));
    imageElement.setAttribute("width", width);
    imageElement.setAttribute("height", height);

    return imageElement;
  }
}

export default XonePDF;
