import XoneFileManager from "./XoneFileManager";
import XonePDF from "./XonePDF";

/**
 * createObject
 * @param {string} obj
 */
window.createObject = (obj) => {
  if (obj.toLowerCase() === "xonepdf") return new XonePDF();
  if (obj.toLowerCase() === "filemanager") return new XoneFileManager();
};

window.$http = {
  /**
   * get
   * @param {string} url
   * @param {Object} options
   * @param {Function} dataFunction
   * @param {Function} errorFunction
   * @return {Promise<void>}
   */
  get: async function(url, options, dataFunction, errorFunction) {
    try {
      const fetchOptions = { method: "GET" };
      if (options?.headers) fetchOptions.headers = options.headers;
      const response = await fetch(url, fetchOptions);
      const data = await response.json();
      dataFunction(data);
    } catch (ex) {
      // Probamos a ejecutar la llamada sin cabecera, por si acaso se ha llamado mal...
      if (options.headers) {
        delete options.headers;
        return window.$http.get(url, options, dataFunction, () =>
          errorFunction("Error executing $http.get", ex)
        );
      }
      errorFunction("Error executing $http.get", ex);
    }
  },

  /**
   * post
   * @param {string} url
   * @param {Object} options
   * @param {Function} dataFunction
   * @param {Function} errorFunction
   * @return {Promise<void>}
   */
  post: async function(url, options, dataFunction, errorFunction) {
    try {
      const fetchOptions = { method: "POST" };
      if (options?.headers) fetchOptions.headers = options.headers;
      if (options?.data) fetchOptions.body = JSON.stringify(options.data);
      const response = await fetch(url, {
        method: "POST",
        fetchOptions,
      });
      const data = await response.json();
      dataFunction(data);
    } catch (ex) {
      // Probamos a ejecutar la llamada sin cabecera, por si acaso se ha llamado mal...
      if (options.headers) {
        delete options.headers;
        return window.$http.post(url, options, dataFunction, () =>
          errorFunction("Error executing $http.post", ex)
        );
      }
      errorFunction("Error executing $http.post", ex);
    }
  },
};

/**
 * XOneOCR
 */
window.XOneOCR = class {
  startScan() {
    console.log("OCR Start Scan");
  }
};

/**
 * fingerprintManager
 */
window.fingerprintManager = { isHardwareAvailable: () => false };
