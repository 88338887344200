<template>
  <teleport v-if="toastElements.length !== 0" to="body">
    <!-- Toast Container -->
    <div class="xone-toast">
      <!-- Toast Items -->
      <div
        v-for="(toast, index) in toastElements"
        :key="`toast-${index}`"
        :class="toast.class"
      >
        {{ toast.message }}
      </div>
    </div>
  </teleport>
</template>

<script>
import { ref } from "vue";
import { setShowToastCallback } from "../../composables/XoneUI";

export default {
  setup() {
    let toastElements = ref([]);
    const duration = 3000;

    // showToast CallBack function
    setShowToastCallback((message) => {
      let obj = toastElements.value.find((e) => e.class === "hide");
      if (!obj) {
        toastElements.value.push({});
        obj = toastElements.value[toastElements.value.length - 1];
      }
      obj.message = message;
      obj.class = "showanimation";
      setTimeout(() => {
        obj.class = "hideanimation";

        setTimeout(() => {
          obj.class = "hide";
          if (
            toastElements.value.filter(
              (e) => e.class === "showanimation" || e.class === "hideanimation"
            ).length === 0
          ) {
            toastElements.value = []; // Clear items
          }
        }, 500);
      }, duration + 500);
    });

    return { toastElements };
  },
};
</script>

<style scoped>
.xone-toast {
  position: absolute;
  bottom: 8px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
  pointer-events: none;
  width: 100vw;
  z-index: 999;
}

.xone-toast div {
  align-self: center;
  text-align: justify;
  padding: 10px 20px;
  margin-bottom: 5px;
  background-color: whitesmoke;
  color: black;
  border-radius: 50px;
  box-shadow: 1.5px 1.5px 1px gray;
  pointer-events: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin: 10px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12);
}

.showanimation {
  animation: slideUp 0.5s;
  opacity: 1;
}

.hideanimation {
  animation: slideDown 0.5s;
  opacity: 0;
}

.hide {
  opacity: 0;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translate(0, 300px) scale(0.5);
  }
  75% {
    opacity: 1;
    transform: translate(0, -5px) scale(1.1, 1);
  }
  100% {
    transform: translate(0, 0) scale(1);
  }
}

@keyframes slideDown {
  0% {
    transform: translate(0, 0);
    opacity: 1.5;
  }
  100% {
    transform: translate(0, 300px);
    opacity: 0;
  }
}

@keyframes size {
  0% {
    transform: scale(0.5);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
</style>