<template>
  <div
    v-if="containerWidth && containerHeight"
    class="xone-layout"
    :style="{
      '--app-width': `${containerWidth}px`,
      '--app-height': `${containerHeight}px`,
    }"
  >
    <div class="xone-dataobjects">
      <router-view />
    </div>
    <SnackBar />
    <Toast />
    <Loader />
    <MsgBox />
    <Camera />
    <Url />
    <Offline />
  </div>
</template>

<script>
import Toast from "./components/uiComponents/Toast.vue";
import SnackBar from "./components/uiComponents/SnackBar.vue";
import Loader from "./components/uiComponents/Loader.vue";
import MsgBox from "./components/uiComponents/MsgBox.vue";
import Camera from "./components/uiComponents/Camera.vue";
import Url from "./components/uiComponents/Url.vue";
import Offline from "./components/uiComponents/Offline.vue";

import {
  provide,
  ref,
  Ref,
  ComputedRef,
  onMounted,
  onUnmounted,
  computed,
  watch,
} from "vue";
import { XoneApplication } from "./composables/appData/core/XoneApplication";
import { FileManagerOnline } from "./composables/appData/Manager/FileManager";
import AppDataHandler from "./composables/AppDataHandler";
import XoneAppHandler from "./composables/XoneAppHandler";
import { useRoute, useRouter } from "vue-router";
import xoneUI from "./composables/XoneUI";
import { XoneDataObject } from "./composables/appData/core/XoneDataObject";

export default {
  components: {
    Toast,
    SnackBar,
    Loader,
    MsgBox,
    Camera,
    Url,
    Offline,
  },
  setup() {
    const version = "";

    console.log(
      `%c b y %c X %c >O n e %c ${version}`,
      "background: whitesmoke; color: #1F3C6E; font-weight: bold; display: block; font-size: 17px;",
      "background: #4CABD5   ; color: white  ; font-weight: bold; display: block; font-size: 17px;",
      "background: #1F3C6E   ; color: white  ; font-weight: bold; display: block; font-size: 17px;",
      "color: #1F3C6E"
    );

    const router = useRouter();
    const route = useRoute();

    /**
     * Handler mappings App node
     * @type {XoneAppHandler|undefined}
     */
    let xoneAppHandler;

    //
    // Manage Window Size / Scale

    /**
     * appWidth
     * @type {Ref<number>}
     */
    let appWidth = ref();

    /**
     * appHeight
     * @type {Ref<number>}
     */
    let appHeight = ref();

    /**
     * containerWidth
     * @type {Ref<number>}
     */
    let containerWidth = ref(null);

    /**
     * containerHeight
     * @type {Ref<number>}
     */
    let containerHeight = ref(null);

    // Check if an input is focused in order to handle keyboard in mobile devices
    const isInputFocused = ref(false);
    provide("isInputFocused", isInputFocused);

    watch(
      () => isInputFocused.value,
      (newValue) => {
        if (!newValue) setTimeout(() => onResize(), 250);
      }
    );

    let resizeTimeout = 25;
    let allowZoom = false;
    /**
     * on window resize
     */
    const onResize = () => {
      if (resizeTimeout) clearTimeout(resizeTimeout);

      resizeTimeout = setTimeout(() => {
        const zoom = allowZoom ? window.devicePixelRatio : 1;
        if (isInputFocused.value) return;
        containerWidth.value =
          (appWidth.value && window.innerWidth > appWidth.value
            ? appWidth.value
            : window.innerWidth) *
            zoom -
          2;
        containerHeight.value =
          (appHeight.value && window.innerHeight > appHeight.value
            ? appHeight.value
            : window.innerHeight) *
            zoom -
          2;
        // Send resolution macros
        xoneAppHandler &&
          xoneAppHandler.setVisualConditionsMacros(
            containerWidth.value,
            containerHeight.value
          );

        sizeConditions.value = xoneAppHandler.sizeConditions;
        orientation.value = xoneAppHandler.orientation;
      }, 50);
    };

    onMounted(() => window.addEventListener("resize", onResize));
    onUnmounted(() => window.removeEventListener("resize", onResize));

    provide("containerSize", { containerWidth, containerHeight });

    /**
     * Size Conditions
     * @type {Ref<string>}
     */
    const sizeConditions = ref("");
    provide("sizeConditions", sizeConditions);

    /**
     * Orientation
     * @type {Ref<string>}
     */
    const orientation = ref("");
    provide("orientation", orientation);

    //
    // Scale Factor

    /**
     * widthFactor
     * @type {ComputedRef<number>}
     */
    const widthFactor = computed(() =>
      appWidth.value ? containerWidth.value / appWidth.value : 1
    );

    /**
     * heightFactor
     * @type {ComputedRef<number>}
     */
    const heightFactor = computed(() =>
      appHeight.value ? containerHeight.value / appHeight.value : 1
    );

    // provide scaleFactor to child components
    provide("scaleFactor", { widthFactor, heightFactor });

    // displayScreenThresholds
    const displayScreenThresholds = ref(false);
    provide("displayScreenThresholds", displayScreenThresholds);

    /**
     * Init appData
     */
    onMounted(async () => {
      const appData = new XoneApplication();

      // provide appData
      AppDataHandler.setAppData(appData);

      // set UI
      appData.setUserInterface(xoneUI);

      // Add method to XoneDataObject prototype
      if (!XoneDataObject.prototype.changeModelValue)
        XoneDataObject.prototype.changeModelValue = function (
          propName,
          newValue
        ) {
          this[propName] = newValue;
        };
      if (!XoneDataObject.prototype.setModel)
        XoneDataObject.prototype.setModel = function (model) {
          this.model = model;
        };

      // Add methods to XoneApplication prototipes
      if (!XoneApplication.prototype.exit)
        XoneApplication.prototype.exit = () => xoneUI.exitApp();
      if (!XoneApplication.prototype.exitApp)
        XoneApplication.prototype.exitApp = () => {
          window.close();
          xoneUI.exitApp();
        };
      if (!XoneApplication.prototype.logout)
        XoneApplication.prototype.logout = () => xoneUI.exitApp();

      // Get App Configuration File and Script_Wrapper
      const getConfig = Function(
        "cbF",
        "(async()=>{try{const c=await import('../configuration.js');const{__SCRIPT_WRAPPERASYNC}=await import('../source/__SCRIPT_WRAPPER__.js');cbF({...c.default,sWA:__SCRIPT_WRAPPERASYNC});}catch(ex){console.error('Error loading configuration.js and __SCRIPT_WRAPPER__.js files',ex);}})()"
      );

      getConfig(async (config) => {
        if (config.displayScreenThresholds)
          displayScreenThresholds.value = true;

        if (config.debugging) window["debugging"] = true;

        allowZoom = config.allowZoom;
        //
        // Init App
        await appData.IniciarApp(
          config.sourcePath,
          config.sWA,
          new FileManagerOnline(),
          `Data Source=${config.apiUrl};xoneuser=${config.clientId};xonepass=${config.clientSecret}`
        );

        xoneAppHandler = new XoneAppHandler(appData);

        // Set app width / height
        const appSize = xoneAppHandler.getAppSize();
        appWidth.value = appSize?.width;
        appHeight.value = appSize?.height;
        onResize();

        // set macros
        xoneAppHandler.setInmutableMacros(appData);

        // // get entry-point, logon-login-coll, autologon
        // const loginColl = appData.getLoginCollectionName(
        //   appData.getCurrentVisualConditions()
        // );
        // const entryPoint = appData.getEntryPointCollection(
        //   appData.getCurrentVisualConditions()
        // );
        autoLogon = xoneAppHandler.getAutoLogon(); // TODO: crear autologon
        doLogin(autoLogon);
      });
    });

    let autoLogon = false;

    const doLogin = async () => {
      const entryPointFake = (xoneUI.entryPointFake = route.params?.id);
      if (entryPointFake) {
        autoLogon = true;
        displayScreenThresholds.value = true;
        xoneUI.entryPointFake = entryPointFake;
      }
      if (autoLogon) {
        return AppDataHandler.getAppData().login({
          userName: "admin",
          password: "",
        });
      }
      // if (!loginColl) // TODO: crear login integrado
      //   return router.push({ name: "Login", params: { id: "integrated" } });

      await AppDataHandler.addNewXoneDataObject(
        AppDataHandler.getAppData().getLoginCollectionName(),
        "Login"
      );

      const routeTo = { name: "Login" };

      if (route.params?.id) routeTo.params = { id: route.params.id };
      router.push(routeTo);
    };

    provide("loginFunction", doLogin);

    return { containerWidth, containerHeight };
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.xone-layout {
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
  overflow: visible;
  max-height: 100vh;
  max-width: 100vw;
}
.xone-navbar {
  flex-shrink: 1;
}

.xone-dataobjects {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12);
  width: var(--app-width);
  height: var(--app-height);
}

@keyframes fadeIn {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideLeft {
  from {
    transform: translate(75%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes slideRight {
  from {
    transform: translate(-75%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes right_in {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(0px);
  }
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Leaflet map routing */
.leaflet-routing-container {
  display: none;
}

/* Loader */

.xone-loader {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 10px 0;
  grid-column: 1 / -1;
}

.xone-loader div:first-child {
  border-radius: 50%;
  border: 5px solid lightgray;
  border-left: 5px solid gray;
  width: 3vh;
  height: 3vh;
  -webkit-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
