<template>
  <!-- SnackBar container -->
  <teleport v-if="snackBarElements.length !== 0" to="body">
    <div class="xone-snackbar">
      <!-- SnackBar items -->
      <div
        v-for="(snackBar, index) in snackBarElements"
        :key="`snackBar-${index}`"
        :class="snackBar.class"
        :style="{
          backgroundColor: snackBar.color,
          color: snackBar.textColor,
          width: snackBar.width && snackBar.width,
          height: snackBar.height && snackBar.height,
        }"
      >
        <!-- Text -->
        <p class="xone-snackbar-text">{{ snackBar.text }}</p>
        <!-- Action -->
        <p
          class="xone-snackbar-action"
          :style="{ color: snackBar.actionTextColor }"
          @click="onSnackBarActionPressed(snackBar)"
        >
          {{ snackBar.actionText }}
        </p>
      </div>
    </div>
  </teleport>
</template>

<script>
import { inject, ref, Ref } from "vue";
import { setShowSnackbarCallback } from "../../composables/XoneUI";

export default {
  setup() {
    let snackBarElements = ref([]);

    // showSnackBar CallBack function
    setShowSnackbarCallback((params) => {
      const {
        color = "gray",
        duration = "short",
        width = "100%",
        height = "auto",
        text = "",
        textColor = "white",
        actionText = "Ok",
        actionTextColor = "white",
        actionMethod = () => {},
      } = typeof params === "string" ? (params = { text: params }) : params;

      let obj = snackBarElements.value.find((e) => e.class === "hide");
      if (!obj) {
        snackBarElements.value.push({});
        obj = snackBarElements.value[snackBarElements.value.length - 1];
      }
      obj.color = color;
      obj.width = width;
      obj.height = height;
      obj.text = text;
      obj.textColor = textColor;
      obj.actionText = actionText;
      obj.actionTextColor = actionTextColor;
      obj.actionMethod = actionMethod;
      obj.class = "showanimation";
      if (duration === "long") setTimeout(() => hideSnackBar(obj), 6500);
      if (duration !== "indeterminate")
        setTimeout(() => hideSnackBar(obj), 3500);
    });

    // Hide SnackBar
    const hideSnackBar = (obj) => {
      if (!obj || obj?.class === "hideanimation" || obj?.class === "hide")
        return;
      obj.class = "hideanimation";

      setTimeout(() => {
        obj.class = "hide";
        if (
          snackBarElements.value.filter(
            (e) => e.class === "showanimation" || e.class === "hideanimation"
          ).length === 0
        ) {
          snackBarElements.value = []; // clear items
        }
      }, 500);
    };

    // SnackBar Action
    const onSnackBarActionPressed = (obj) => {
      if (obj && obj.class !== "hide") hideSnackBar(obj);
      obj?.actionMethod && obj.actionMethod();
    };

    /**
     * Window Size
     * @type {{containerWidth: Ref<number>}}
     */
    const { containerWidth } = inject("containerSize");

    return {
      snackBarElements,
      onSnackBarActionPressed,
      containerWidth,
    };
  },
};
</script>

<style scoped>
.xone-snackbar {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
  bottom: 0px;
  flex-shrink: 1;
  min-height: 100px;
  width: 100vw;
  z-index: 999;
}

.xone-snackbar div {
  min-height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: white;
  border-radius: 5px;
  text-align: center;
  justify-self: center;
}

.xone-snackbar div .xone-snackbar-text {
  flex-grow: 1;
  padding: 5px;
}

.xone-snackbar div .xone-snackbar-action {
  padding: 5px 10px;
  font-weight: bold;
  margin-right: 30px;
}

.xone-snackbar div .xone-snackbar-action:hover {
  cursor: pointer;
}

.showanimation {
  animation: slideUp 0.5s;
  opacity: 1;
}

.hideanimation {
  animation: slideDown 0.5s;
  opacity: 0;
}

.hide {
  opacity: 0;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translate(0, 300px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes slideDown {
  from {
    transform: translate(0, 0);
    opacity: 1;
  }
  to {
    transform: translate(0, 300px);
    opacity: 0;
  }
}
</style>