<template>
  <div
    ref="contentsElement"
    class="xone-contents"
    :class="{ 'xone-gridview': isGridView, 'xone-slideview': isSlideView }"
    :style="{
      // Size
      height:
        !attributes.height || attributes.height === 'auto'
          ? 'auto'
          : (controlHeight && `${controlHeight}px`) || 'auto',
      maxWidth: attributes.viewMode === 'picturemap' && `${controlWidth}px`,
      // Background
      backgroundColor: attributes.bgColor,
      // is GridView? -> define columns
      'grid-template-columns': isGridView && gridTemplateColumns,
      // is PictureMap? -> allow scrolls
      overflow: attributes.viewMode === 'picturemap' && 'auto',
      // Animation
      animation: isContents && 'slideDown 0.3s',
      // Set variables
      '--contents-width': `${controlWidth}px`,
      '--contents-height': `${controlHeight}px`,
      '--contents-max-height': `${
        fitHeight
          ? `calc(${fitHeight}px - var(--margin-bottom))`
          : `${controlHeight}px`
      }`,
    }"
  >
    <router-view v-if="isRouterView"></router-view>
    <!-- Map View -->
    <Map
      v-if="isMapView"
      :xoneDataObject="xoneDataObject"
      :controlHeight="fitHeight ? fitHeight : controlHeight"
      :controlWidth="controlWidth"
      :attributes="attributes"
    ></Map>
    <!-- Calendar View -->
    <Calendar
      v-if="isCalendarView"
      :xoneDataObject="xoneDataObject"
      :controlHeight="fitHeight ? fitHeight : controlHeight"
      :controlWidth="controlWidth"
      :attributes="attributes"
    ></Calendar>
    <!-- Calendar View -->
    <PictureMap
      v-if="isPictureMapView"
      :xoneDataObject="xoneDataObject"
      :controlHeight="fitHeight ? fitHeight : controlHeight"
      :controlWidth="controlWidth"
      :attributes="attributes"
    ></PictureMap>
    <!-- Chart View -->
    <template v-else-if="isChartView">
      <ChartBar
        v-if="
          attributes.viewMode === 'barchart' ||
          attributes.viewMode === '3dbarchart' ||
          attributes.viewMode === 'stackedbarchart' ||
          attributes.viewMode === 'linechart'
        "
        :xoneDataObject="xoneDataObject"
        :controlHeight="fitHeight ? fitHeight : controlHeight"
        :controlWidth="controlWidth"
        :attributes="attributes"
      >
      </ChartBar>
      <ChartPie
        v-if="
          attributes.viewMode === 'piechart' ||
          attributes.viewMode === 'piechart2'
        "
        :xoneDataObject="xoneDataObject"
        :controlHeight="fitHeight ? fitHeight : controlHeight"
        :controlWidth="controlWidth"
        :attributes="attributes"
      >
      </ChartPie>
    </template>
    <!-- Other Contents -->
    <template
      v-else
      v-for="rowInfo in contentsRowsInfo"
      :key="`${breadcumbId}-${attributes.name}-${rowInfo.id}`"
    >
      <ContentsRow
        :rowInfo="rowInfo"
        :controlHeight="fitHeight ? fitHeight : controlHeight"
        :controlWidth="
          isGridView ? controlWidth / attributes.galleryColumns : controlWidth
        "
        :attributes="attributes"
        :isSlideView="isSlideView"
        :isDisableEdit="isDisableEdit"
        :isExpanView="isExpanView"
      ></ContentsRow>
    </template>
    <!-- Loader -->
    <div v-if="isLoading" class="xone-loader">
      <div></div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  inject,
  onMounted,
  provide,
  ref,
  Ref,
  watchEffect,
  PropType,
  onUnmounted,
  watch,
  nextTick,
} from "vue";

import ContentsRow from "@/components/propComponents/contentsComponents/ContentsRow";
import Map from "@/components/propComponents/contentsComponents/Map";
import Calendar from "@/components/propComponents/contentsComponents/Calendar";
import ChartBar from "@/components/propComponents/contentsComponents/ChartBar";
import ChartPie from "@/components/propComponents/contentsComponents/ChartPie";
import PictureMap from "@/components/propComponents/contentsComponents/PictureMap";
import { XoneDataObject } from "../../composables/appData/core/XoneDataObject";
import { XoneDataCollection } from "../../composables/appData/core/XoneDataCollection";
import { PropAttributes } from "../../composables/XoneAttributesHandler";
import { XoneControl, XoneView } from "../../composables/XoneViewsHandler";
import { XoneContentsLoaderHandler } from "../../composables/ContentsLoaderHandler";
import { generateUniqueId } from "../../composables/helperFunctions/StringHelper";

export default {
  name: "Contents",
  props: {
    /**
     * xoneDataObject
     * @type {PropType<XoneDataObject>}
     * */
    xoneDataObject: { type: Object, required: true },
    /**
     * attributes
     * @type { PropType<PropAttributes>}
     */
    attributes: { type: Object, default: null, required: true },
    isDisableEdit: { type: Boolean, required: true },
    controlHeight: { type: Number, default: 0 },
    controlWidth: { type: Number, default: 0 },
  },
  components: {
    ContentsRow,
    Map,
    ChartBar,
    Calendar,
    ChartPie,
    PictureMap,
  },
  setup(props) {
    const { isContents } = inject("objectInfo");

    /**
     * Contents
     * @type {Ref<XoneDataCollection>}
     */
    const contents = ref();
    // provide contents to child components
    provide("contents", contents);

    const isLoading = ref(false);

    /**
     * xoneView
     * @type {XoneView}
     */
    const xoneView = inject("xoneView");

    // provide item to load to child components
    const loadedRowsLength = ref(0);
    provide("loadedRowsLength", loadedRowsLength);

    /**
     * breadcumbId
     * @type {string}
     */
    const breadcumbId = inject("breadcumbId");

    /**
     * contentsElement
     * @type {Ref<HTMLElement>}
     */
    const contentsElement = ref();

    /**
     * Window Size
     * @type {{containerHeight: Ref<number>}}
     */
    const { containerHeight } = inject("containerSize");

    /**
     * fit height
     * @type {Ref<number>}
     */
    const fitHeight = ref();

    /**
     * Calculate and adjust height to parent container when height attribute is null or auto
     */
    const fitHeightToContainer = async () => {
      if (props.attributes.viewMode === "picturemap") return;
      try {
        if (
          !contentsElement.value ||
          (props.attributes.height && props.attributes.height !== "auto")
        ) {
          if (fitHeight.value) fitHeight.value = null;
          return;
        }

        const fit = () => {
          let top = contentsElement.value.parentElement.offsetTop;

          if (fitHeight.value !== props.controlHeight - top) {
            fitHeight.value = props.controlHeight - top;
            return true;
          }
          return false;
        };

        setTimeout(() => fit(), 250);
        fit();
      } catch {}
    };

    onMounted(() => fitHeightToContainer());

    watch(
      () => containerHeight.value,
      () => {
        fitHeightToContainer();
      }
    );

    /**
     * selectedItem
     * @type {Ref<number>}
     */
    const selectedItem = ref(null);
    provide("selectedItem", selectedItem);

    //
    // Data

    const xoneContentsLoader = new XoneContentsLoaderHandler(
      props.attributes.name,
      breadcumbId
    );

    xoneContentsLoader.setIsLoading(isLoading.value);

    onMounted(() =>
      xoneContentsLoader.bindOnScrollEvent(contentsElement.value)
    );
    onUnmounted(() => xoneContentsLoader.clear());

    let lastRefreshActionId = null;

    /**
     * LoadAll contents async
     */
    const refresh = async () => {
      // Refresh only last refresh action
      const refreshActionId = generateUniqueId();
      lastRefreshActionId = refreshActionId;

      // Wait last refresh ends
      while (isLoading.value)
        await new Promise((resolve) => setTimeout(() => resolve(), 100));

      // Check if refresh action is the last one called
      if (lastRefreshActionId !== refreshActionId) return;

      try {
        // Show loader div
        isLoading.value = true;
        // Reset contents row info
        xoneContentsLoader.resetRowsInfo();
        await nextTick();

        // load contents
        if (!contents.value)
          contents.value = await props.xoneDataObject.getContents(
            props.attributes.contents
          );

        // LoadAll
        await contents.value.loadAll(false); // TODO: cargar datos según scroll await contents.value.loadAll(true, { start: 0, length: 1000 });

        // Initializate rows count
        xoneContentsLoader.setRowsLength(contents.value.length);

        // Initializate loadedRowsLength
        loadedRowsLength.value = 0;

        // Load Rows Info
        await xoneContentsLoader.loadRowsInfo(0);
      } catch (ex) {
        console.error(ex);
      } finally {
        isLoading.value = false;
      }
    };

    /**
     * Group Id
     * @type {string}
     */
    const groupId = inject("groupId");

    /**
     * Group active
     * @type {Ref<string>}
     */
    const { activeGroup } = inject("groupHandler");
    let firstGroup;

    onMounted(() => {
      // Vamos a cargar el contents cuando su grupo sea el activo
      if (
        isMapView.value ||
        isChartView.value ||
        isCalendarView.value ||
        isPictureMapView.value ||
        isRouterView.value
      )
        return;
      // Add control to view
      const xoneControl = new XoneControl(props.attributes.name, true);
      xoneControl.refresh = refresh;
      xoneControl.refreshRow = (index) => {
        try {
          xoneContentsLoader.getContentsRowsInfo().value[index].refresh();
        } catch {}
      };
      xoneControl.refreshSelectedRow = () => {
        try {
          xoneContentsLoader
            .getContentsRowsInfo()
            .value[selectedItem.value].refresh();
        } catch {}
      };

      xoneView.addControl(xoneControl);
      watchEffect(async () => {
        if (!firstGroup) firstGroup = activeGroup.value;

        if (contents.value || groupId !== activeGroup.value) return;

        setTimeout(
          () =>
            // refresh contents
            refresh(),
          firstGroup !== groupId ? 200 : 0 // Vamos a dejar tiempo para que se haga la transicion del grupo antes de empezar a cargar el contents, asi ira mucho mas fluido
        );
      });
    });

    // Clear Contents
    onUnmounted(() => {
      if (contents.value) contents.value.clear();
    });

    //
    // viewmode gridview
    const isGridView = computed(
      () =>
        props.attributes.viewMode === "gridview" &&
        !isNaN(props.attributes.galleryColumns)
    );

    const gridTemplateColumns = computed(() => {
      if (!isGridView.value) return;

      return `repeat(${props.attributes.galleryColumns}, ${
        props.controlWidth / props.attributes.galleryColumns
      }px)`;
    });

    //
    //
    // Viewmodes

    //
    // viewmode mapview

    const isMapView = computed(
      () =>
        props.attributes.viewMode === "mapview" ||
        props.attributes.viewMode === "openstreetmap"
    );

    //
    // viewmode slideview

    const isSlideView = computed(
      () => props.attributes.viewMode === "slideview"
    );

    onMounted(() => {
      if (isSlideView.value) {
        contentsElement.value.style.pointerEvents = "all";
        contentsElement.value.setAttribute("swipeable", false);
      }
    });

    //
    // viewmode calendar

    const isCalendarView = computed(
      () => props.attributes.viewMode === "calendarview"
    );

    // autoslide
    let autoslideInterval;
    let currentSlideIndex = -1;

    onMounted(() => {
      if (isSlideView.value) {
        watch(
          () => loadedRowsLength.value,
          async (newValue) => {
            if (newValue !== contents.value?.length) return;
            await nextTick();

            if (props.attributes.autoslideDelay) {
              contentsElement.value.scrollTo(0, 0);
              // create interval
              autoslideInterval = setInterval(() => {
                if (xoneContentsLoader.getRowsLength() === 0) return;

                currentSlideIndex += 1;
                if (currentSlideIndex >= xoneContentsLoader.getRowsLength())
                  currentSlideIndex = 0;

                /**
                 * Get element
                 * @type {HTMLElement}
                 */
                const element = document.getElementById(
                  `${props.attributes.name.replace(
                    "@",
                    ""
                  )}${currentSlideIndex}${breadcumbId}`
                );
                if (!element) return;

                // Scroll to element
                contentsElement.value.scrollTo({
                  left: element.offsetLeft,
                  top: 0,
                  behavior: "smooth",
                });
              }, props.attributes.autoslideDelay * 1000);
            } else contentsElement.value.scrollTo(0, 0);
          }
        );
      }
    });

    // clear autoslide interval
    onUnmounted(() => {
      if (autoslideInterval) clearInterval(autoslideInterval);
    });

    //
    // viewmode chart
    const isChartView = computed(
      () =>
        props.attributes.viewMode &&
        props.attributes.viewMode.toString().contains("chart")
    );

    //
    // viewmode picturemap

    const isPictureMapView = computed(
      () => props.attributes.viewMode === "picturemap"
    );

    //
    // viewmode Expan

    const isExpanView = computed(
      () => props.attributes.viewMode === "expanview"
    );

    // expan item selected
    provide("onExpanItemSelected", (rowInfo) => {
      if (rowInfo.isExpanded) return (rowInfo.isExpanded = false);
      xoneContentsLoader
        .getContentsRowsInfo()
        .value.forEach((e) => (e.isExpanded = false));
      rowInfo.isExpanded = true;
    });

    //
    // viewmode router-view

    const isRouterView = computed(
      () => props.attributes.viewMode === "routerview"
    );

    return {
      isContents,
      contentsElement,
      fitHeight,
      contentsRowsInfo: xoneContentsLoader.getContentsRowsInfo(),
      isLoading,
      breadcumbId,
      isGridView,
      gridTemplateColumns,
      isMapView,
      isSlideView,
      isCalendarView,
      isChartView,
      isPictureMapView,
      isExpanView,
      isRouterView,
    };
  },
};
</script>

<style scoped>
.xone-contents {
  box-sizing: border-box;
  width: calc(100%);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.1s;
  animation: fadeIn 0.3s;

  max-height: var(--contents-max-height);
}
.xone-contents div {
  scroll-snap-align: start;
}

.xone-gridview {
  display: grid;
}

.xone-slideview {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}
</style>