import { nextTick, ref, Ref } from "vue";

/**
 * @typedef {Object} RowInfo
 * @property {number} id
 * @property {boolean} visible
 * @property {string} height
 * @property {string} width
 * @property {booolean} isExpanded
 * @property {function} refresh
 * @property {function} setRefresh
 */

/**
 * XoneContentsLoaderHandler
 */
export class XoneContentsLoaderHandler {
  /**
   * Contents items info (index, size, visibility)...
   * @type {Ref<Array<RowInfo>>}
   */
  contentsRowsInfo = ref([]);

  /**
   * @type {HTMLElement}
   */
  element;

  /**
   * @type {number}
   */
  rowsPerPage = 20;

  /**
   * @type {boolean}
   */
  isLoadingData = false;

  /**
   * @type {boolean}
   */
  isCheckingRowVisibility;
  /**
   * @type {boolean}
   */
  isLoadingRowsInfo;

  /**
   * @type {boolean}
   */
  checkRowVisibilityWaiting = false;

  /**
   * @type {number}
   */
  rowsLength = 0;

  /**
   * @type {Map<string,HTMLElement>}
   */
  mapDivElements = new Map();

  /**
   * @type {string}
   */
  elementName;

  /**
   * @type {string}
   */
  breadcumbId;

  /**
   * @type {boolean}
   */
  isLoading = false;

  /**
   * Constructor
   * @param {string} elementName
   * @param {string} breadcumbId
   */
  constructor(elementName, breadcumbId) {
    this.elementName = elementName;
    this.breadcumbId = breadcumbId;
  }

  /**
   * bindOnScroll
   * @param {HTMLElement} element
   */
  bindOnScrollEvent(element) {
    this.element = element;
    element.addEventListener("scroll", this.onScroll.bind(this));
  }

  /**
   * clear
   */
  clear() {
    this.element.removeEventListener("scroll", this.onScroll);
  }

  /**
   * getContentsRowsInfo
   * @returns {Ref<Array<RowInfo>>}
   */
  getContentsRowsInfo() {
    return this.contentsRowsInfo;
  }

  /**
   * setIsLoading
   * @param {boolean} value
   */
  setIsLoading(value) {
    this.isLoading = value;
  }

  /**
   * getIsLoading
   * @return {boolean} value
   */
  getIsLoading() {
    return this.isLoading;
  }

  /**
   * setrowsLength
   * @param {number} value
   */
  setRowsLength(value) {
    this.rowsLength = value;
  }

  /**
   * getrowsLength
   * @returns {number} value
   */
  getRowsLength() {
    return this.rowsLength;
  }

  /**
   * resetRowsInfo
   */
  resetRowsInfo() {
    this.contentsRowsInfo.value = [];
  }

  /**
   * On contents scroll
   * @param {*} e
   */
  onScroll(e) {
    if (this.isLoadingData) return;
    // Check elements visibility in scroll
    if (!this.isCheckingRowVisibility) this.checkScrollElementsVisibility(e);
    // Check again when checkScrollElementsVisibility ends
    else if (!this.checkRowVisibilityWaiting)
      this.checkRowVisibilityWaiting = true;
    // Check if we have to load more child components
    if (!this.isLoadingRowsInfo) this.checkLoadRowsInfo(e);
  }

  /**
   * checkScrollElementsVisibility
   * @param {*} e
   */
  checkScrollElementsVisibility(e) {
    this.isCheckingRowVisibility = true;
    setTimeout(() => {
      this.contentsRowsInfo.value.forEach((/** @type {RowInfo} */ element) => {
        if (this.isLoadingData) return;

        let divElement = this.mapDivElements.get(element.id);

        if (!divElement) {
          divElement = document.getElementById(
            `${this.elementName.replace("@", "")}${element.id}${
              this.breadcumbId
            }`
          );
          if (!divElement) return;
          this.mapDivElements.set(element.id, divElement);
        }

        // Hide elements above scroll
        if (
          divElement.offsetTop + divElement.clientHeight + window.outerHeight <
          e.target.scrollTop
        ) {
          // set height
          if (element.height === "auto")
            element.height = divElement.clientHeight
              ? divElement.clientHeight + "px"
              : "auto";
          // set width
          if (element.width === "auto")
            element.width = divElement.clientWidth
              ? divElement.clientWidth + "px"
              : "auto";
          // set visibility
          element.visible = false;

          // Hide elements below scroll
        } else if (
          divElement.offsetTop + divElement.clientHeight >
          e.target.scrollTop + e.target.clientHeight + window.outerHeight
        ) {
          // set height
          if (element.height === "auto")
            element.height = divElement.clientHeight
              ? divElement.clientHeight + "px"
              : "auto";
          // set width
          if (element.width === "auto")
            element.width = divElement.clientWidth
              ? divElement.clientWidth + "px"
              : "auto";
          // set visibility
          element.visible = false;

          // Show elements on scroll
        } else if (!element.visible) {
          element.height = "auto";
          element.width = "auto";
          element.visible = true;
        }
        setTimeout(() => {
          this.isCheckingRowVisibility = false;
          if (this.checkRowVisibilityWaiting) {
            this.checkRowVisibilityWaiting = false;
            this.checkScrollElementsVisibility(e);
          }
        }, 250);
      });
    }, 250);
  }

  /**
   * checkLoadRowsInfo
   * @param {*} e
   */
  checkLoadRowsInfo(e) {
    if (
      e.target.scrollHeight - e.target.clientHeight - window.outerHeight >=
        e.target.scrollTop ||
      this.isLoadingData
    )
      return;

    this.isLoadingRowsInfo = true;

    setTimeout(async () => {
      // load rows info
      if (!this.isLoadingData) return await this.loadRowsInfo();

      setTimeout(() => {
        if (!this.isLoadingRowsInfo) this.checkLoadRowsInfo(e);
      }, 250);
    }, 250);
  }

  /**
   * loadRowsInfo
   */
  async loadRowsInfo(index = null) {
    if (this.isLoadingData) return;

    if (index === 0) {
      this.resetRowsInfo();
      await nextTick();
    }

    if (this.contentsRowsInfo.value.length < this.rowsLength)
      this.isLoading = true;

    this.isLoadingRowsInfo = true;
    const init =
      index || this.contentsRowsInfo.value.length === 0
        ? 0
        : this.contentsRowsInfo.value[this.contentsRowsInfo.value.length - 1]
            .id + 1;
    const end = init + this.rowsPerPage;
    for (let i = init; i < end; i++) {
      if (i < this.rowsLength) {
        /** @type {RowInfo} */
        const rowInfo = {
          id: i,
          visible: true,
          height: "auto",
          width: "auto",
          isExpanded: false,
          refresh: () => {},
          setRefresh: (callbackFunction) =>
            (rowInfo.refresh = callbackFunction),
        };
        this.contentsRowsInfo.value.push(rowInfo);
      }
    }
    this.isLoadingRowsInfo = false;
    this.isLoading = false;
  }
}
