import { XoneDataObject } from "./appData/core/XoneDataObject";
import { generateUniqueId } from "./helperFunctions/StringHelper";
import AppDataHandler from "./AppDataHandler";

class XoneViewsHandler {
  /**
   * _instance
   * @type {XoneViewsHandler}
   */
  static _instance;

  /**
   * views
   * @type {WeakMap<XoneDataObject, XoneView>}
   */
  viewsMap = new Map();

  constructor() {
    if (XoneViewsHandler._instance) return XoneViewsHandler._instance;
    XoneViewsHandler._instance = this;
  }

  /**
   * Add View
   * @param {XoneDataObject} xoneDataObject
   * @param {XoneView} xoneView
   */
  addView(xoneDataObject, xoneView) {
    if (!xoneDataObject["_XoneHashId"])
      xoneDataObject["_XoneHashId"] = Array(3).join(generateUniqueId());
    this.viewsMap.set(xoneDataObject["_XoneHashId"], xoneView);
  }

  /**
   * Get View
   * @param {XoneDataObject} xoneDataObject
   * @returns {XoneView}
   */
  getView(xoneDataObject) {
    return this.viewsMap.has(xoneDataObject["_XoneHashId"])
      ? this.viewsMap.get(xoneDataObject["_XoneHashId"])
      : null;
  }
}

/**
 * @type {XoneViewsHandler}
 */
export const xoneViewsHandler = new XoneViewsHandler();

export class XoneView {
  /**
   * bindedEvents
   * @type {Array<object>}
   */
  _bindedEvents = [];

  /**
   * @type {XoneDataObject}
   */
  _xoneDataObject;

  /**
   * constructor
   * @param {XoneDataObject} xoneDataObject
   */
  constructor(xoneDataObject) {
    const xoneViewsHandler = new XoneViewsHandler();
    xoneViewsHandler.addView(xoneDataObject, this);
    this._xoneDataObject = xoneDataObject;
  }

  get bindedEvents() {
    return this._bindedEvents;
  }

  /**
   * addControl
   * @param {XoneControl} control
   */
  addControl(control) {
    this[control.name] = control;
  }

  /**
   * Bind event
   * @param  {...string} Args
   */
  bind(...Args) {
    if (!Args || Args.length < 3)
      return console.error(
        "Error calling bind method. You need 3 arguments at least."
      );

    let bindedEvent = this._bindedEvents.find(
      (e) =>
        e.field === Args[0] && e.eventName === Args[1].toString().toLowerCase()
    );

    if (!bindedEvent)
      bindedEvent = {
        fieldName: Args[0],
        eventName: Args[1].toString().toLowerCase(),
      };

    if (Args.length === 3) bindedEvent.action = Args[2];
    else {
      bindedEvent.params = Args[2];
      bindedEvent.action = Args[3];
    }

    // Add event bind
    this._bindedEvents.push(bindedEvent);
  }

  /**
   * exit current view
   */
  exit() {
    AppDataHandler.clearbreadcumbFrom(this._xoneDataObject["_XoneHashId"]);
  }

  /**
   * Refresh
   * @param  {...any} Props
   */
  refresh(...Props) {
    const props = Props.length === 1 ? Props[0].toString().split(",") : Props;
    props.forEach((/** @type {*} */ e) => {
      if (this[e]?.refresh) this[e].refresh();
    });
    if (Props.length === 0)
      Object.values(this).forEach((e) => {
        if (!e) return;
        if (e.isContents && e.refresh) e.refresh();
      });
  }

  /**
   * Refresh All
   */
  refreshAll() {
    this.refresh();
  }

  /**
   * refreshValue
   * @param {*} Props
   */
  refreshValue = (Props) => {
    this.refresh(Props);
  };
}

export class XoneControl {
  refresh = () => {};

  refreshValue = () => {};

  /**
   * constructor
   * @param {string} name
   * @param {boolean} [isContents]
   */
  constructor(name, isContents = false) {
    this.name = name;
    this.isContents = isContents;
  }
}
