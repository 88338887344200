<template>
  <div
    ref="containerElement"
    v-show="isActive && !isDisableVisible"
    :class="[
      'xone-frame',
      attributes.keepAspectRatio && 'keep-aspect-ratio',
      attributes.framebox && 'xone-frame-borders',
    ]"
    :name="attributes.name"
    :style="{
      // Margins
      marginTop: margins.top + 'px',
      marginRight: margins.right + 'px',
      marginBottom: margins.bottom + 'px',
      marginLeft: margins.left + 'px',
      // Size
      width:
        (attributes.node === 'group' &&
          !attributes.drawerOrientation &&
          '100%') ||
        (controlWidth && `${controlWidth}px`) ||
        'auto',
      height:
        (attributes.node === 'group' &&
          !attributes.drawerOrientation &&
          !attributes.fixed &&
          '100%') ||
        (controlHeight &&
          attributes.node !== 'group' &&
          `${controlHeight}px`) ||
        'auto',
      // Background
      backgroundColor: displayScreenThresholds
        ? '#00ff0025'
        : attributes.bgColor,
      backgroundImage: attributes.image && `url(/icons/${attributes.image})`,
      // Border
      border: displayScreenThresholds && `1px solid ${attributes.borderColor}`,
      borderRadius: attributes.borderCornerRadius,
      // Align
      justifyContent: attributes.align.column,
      // Scrolls
      overflowY:
        (attributes.scroll && attributes.scrollOrientation === 'vertical') ||
        attributes.node === 'group'
          ? 'auto'
          : 'hidden',
      overflowX:
        attributes.scroll && attributes.scrollOrientation === 'horizontal'
          ? 'auto'
          : 'hidden',
      // Floating
      position: attributes.floating.floating && 'absolute',
      zIndex: attributes.floating.floating && '1',
      top: attributes.floating.floating && floatingTop && floatingTop,
      left: attributes.floating.floating && floatingLeft && floatingLeft,
      // Animation
      animation: attributes.disableVisible && 'fadeIn 0.5s',
    }"
  >
    <!-- Rows of the container -->
    <template v-if="attributes.node === 'group' || !isDisableVisible">
      <Row
        v-for="(row, irows) in rows"
        :key="`${attributes.name}-${irows}`"
        :style="{
          // Align
          justifyContent: attributes.align.row,
          alignItems: attributes.align.column,
        }"
      >
        <!-- Columns of the Row -->
        <Column
          v-for="(column, iColumns) in row"
          :key="`${column.attributes.name}-${iColumns}`"
          :attributes="attributes"
        >
          <!-- Container -->
          <Container
            v-if="
              column.attributes.node === 'frame' ||
              column.attributes.node === 'group'
            "
            :xoneDataObject="xoneDataObject"
            :control="column"
            :containerWidth="controlWidth ? controlWidth : containerWidth"
            :containerHeight="controlHeight ? controlHeight : containerHeight"
          ></Container>
          <!-- Prop -->
          <Prop
            v-if="column.attributes.node === 'prop'"
            :xoneDataObject="xoneDataObject"
            :control="column"
            :containerWidth="controlWidth ? controlWidth : containerWidth"
            :containerHeight="controlHeight ? controlHeight : containerHeight"
          ></Prop>
        </Column>
      </Row>
    </template>
  </div>
</template>

<script>
import {
  computed,
  inject,
  onMounted,
  ref,
  Ref,
  ComputedRef,
  watch,
  provide,
  nextTick,
} from "vue";
import Row from "@/components/Row";
import Column from "@/components/Column";
import Prop from "@/components/Prop";
import {
  xoneAttributesHandler,
  ContainerAttributes,
  Margins,
} from "../composables/XoneAttributesHandler";
import { XoneDataObject } from "../composables/appData/core/XoneDataObject";
import { XoneControl, XoneView } from "../composables/XoneViewsHandler";

export default {
  name: "Container",
  components: {
    Row,
    Column,
    Prop,
  },
  props: {
    /**
     * xoneDataObject
     * @type {PropType<XoneDataObject>}
     */
    xoneDataObject: { type: Object, required: true },
    control: { type: Object, required: true },
    containerWidth: { type: Number, default: 0 },
    containerHeight: { type: Number, default: 0 },
    visibilityBit: { type: Number, default: 1 },
  },
  /**
   * Group / Frame control
   */
  setup(props) {
    const displayScreenThresholds = inject("displayScreenThresholds");

    /**
     * Attributes model
     * @type {ComputedRef<ContainerAttributes>}
     */
    const attributesModel = computed(() =>
      xoneAttributesHandler.getContainerAttributes(props.control.attributes)
    );

    /**
     * Reactive Attributes
     * @type {Ref<ContainerAttributes>}
     */
    const attributes = ref({});

    // Observe changes in attributes model and data model with ##FLD_ fields and fetch them to attributes
    xoneAttributesHandler.watchAttributes(
      attributes,
      attributesModel,
      props.xoneDataObject?.model
    );

    // Provide group id
    if (attributes.value.node === "group")
      provide("groupId", attributes.value.id);

    /**
     * Check if disablevisible
     * @type {ComputedRef<boolean>}
     */
    const isDisableVisible = computed(() => {
      // Frame without children
      if (
        attributes.value.node === "frame" &&
        props.control.controls.length === 0
      )
        return true;
      // No disablevisible attribute
      if ((attributes.value.disableVisible ?? "") === "") return false;
      // Eval disablevisible formula
      return xoneAttributesHandler.evalFormula(
        attributes.value.disableVisible,
        props.xoneDataObject?.model
      );
    });

    /**
     * Group active
     * @type {Ref<string>}
     */
    const { activeGroup } = inject("groupHandler");

    /**
     * Is current group active
     * @type {ComputedRef<boolean>}
     */
    const isActive = computed(() => {
      return (
        attributes.value.node !== "group" ||
        attributes.value.fixed ||
        attributes.value.id === activeGroup.value ||
        attributes.value.drawerOrientation ||
        props.visibilityBit === 4
      );
    });

    // Execute onfocus method
    if (attributes.value.node === "group" && attributes.value.onFocus)
      watch(
        () => activeGroup.value,
        async (newValue) => {
          if (newValue !== attributes.value.id) return;
          await xoneAttributesHandler.executeMethod(
            attributes.value.onFocus,
            props.xoneDataObject
          );
        }
      );

    /**
     * objectInfo
     * @type {Object}
     */
    const objectInfo = inject("objectInfo");

    /**
     * Container rows / columns
     * @type {ComputedRef<Array>}
     */
    const rows = computed(() => {
      let rowsArr = [];
      let row = [];
      props.control.controls?.forEach((e) => {
        // Set width and height = 100% if group has only 1 child and it's a frame
        if (
          attributes.value.node === "group" &&
          props.control.controls.length === 1 &&
          e.attributes.node === "frame" &&
          !attributes.value.fixed &&
          !attributes.value.floating?.floating &&
          !attributes.value.floating?.drawerOrientation
        ) {
          if (!objectInfo.isMsgBox && !objectInfo.isContents) {
            e.attributes.height = "100%";
            e.attributes.width = "100%";
          }
        }
        const controlAttributes = xoneAttributesHandler.getContainerAttributes(
          e.attributes
        );
        // newline true
        if (controlAttributes.newLine) {
          if (row.length !== 0) {
            rowsArr.push(row);
            row = [];
          }
          row.push(e);
        }
        // newline false
        else {
          row.push(e);
        }
      });
      rowsArr.push(row);
      return rowsArr;
    });

    /**
     * Scale Factor
     * @type {{widthFactor: ComputedRef<number>, heightFactor: ComputedRef<number>}}
     */
    const { widthFactor, heightFactor } = inject("scaleFactor");

    /**
     * Calculate width of the current container
     * @type {ComputedRef<number>}
     */
    const controlWidth = computed(() =>
      xoneAttributesHandler.getControlScaled(
        attributes.value.width,
        props.containerWidth,
        widthFactor.value
      )
    );

    /**
     * Calculate height of the current container
     * @type {ComputedRef<number>}
     */
    const controlHeight = computed(() =>
      xoneAttributesHandler.getControlScaled(
        attributes.value.height,
        props.containerHeight,
        heightFactor.value
      )
    );

    /**
     * Margins of the current container
     * @type {ComputedRef<Margins>}
     */
    const margins = computed(() => ({
      top: xoneAttributesHandler.getControlScaled(
        attributes.value.margins.top,
        props.containerHeight,
        heightFactor.value
      ),
      left: xoneAttributesHandler.getControlScaled(
        attributes.value.margins.left,
        props.containerWidth,
        widthFactor.value
      ),
      bottom: xoneAttributesHandler.getControlScaled(
        attributes.value.margins.bottom,
        props.containerHeight,
        heightFactor.value
      ),
      right: xoneAttributesHandler.getControlScaled(
        attributes.value.margins.right,
        props.containerWidth,
        widthFactor.value
      ),
    }));

    /**
     * calculate floating top
     * @type {ComputedRef<string>}
     */
    const floatingTop = computed(() =>
      xoneAttributesHandler.getScaledPosition(
        attributes.value.floating.top,
        heightFactor.value
      )
    );

    /**
     * calculate floating leeft
     * @type {ComputedRef<string>}
     */
    const floatingLeft = computed(() =>
      xoneAttributesHandler.getScaledPosition(
        attributes.value.floating.left,
        widthFactor.value
      )
    );

    //
    // Handler refresh
    /**
     * xoneView
     * @type {XoneView}
     */
    const xoneView = inject("xoneView");

    onMounted(() => {
      // Add control to view
      const xoneControl = new XoneControl(attributes.value.name);
      xoneControl.refresh = () =>
        props.control.controls.forEach((e) => {
          if (xoneView[e.attributes.name]?.refresh)
            xoneView[e.attributes.name].refresh();
        });
      xoneView.addControl(xoneControl);
    });

    /**
     * containerElement
     * @type {Ref<HTMLElement>}
     */
    const containerElement = ref();

    // Flex no se comporta bien haciendo un justify-center center cuando tiene scroll, compruebo si lo tiene y se lo quito en tal caso
    const fixJustifyCenter = () => {
      if (
        attributes.value.align.column === "center" &&
        containerElement.value.offsetHeight <
          containerElement.value.scrollHeight
      )
        containerElement.value.style.justifyContent = null;
    };

    nextTick(() => fixJustifyCenter());

    watch(
      () => props.containerHeight,
      () => fixJustifyCenter()
    );

    return {
      containerElement,
      displayScreenThresholds,
      attributes,
      isActive,
      rows,
      controlWidth,
      controlHeight,
      margins,
      isDisableVisible,
      floatingTop,
      floatingLeft,
    };
  },
};
</script>
<style scoped>
.xone-frame {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: contain; */
  background-size: 100% 100%;
  overflow-x: hidden;
  overflow-y: auto;
  /* transition: width 0.3s, height 0.3s; */
  box-sizing: border-box;
}

.xone-frame-borders {
  border: 1px solid gray;
  border-radius: 5px;
}

.keep-aspect-ratio {
  background-size: contain;
}

.tabcontent {
  height: auto;
  width: auto;
  flex-shrink: 1;
  flex-grow: 1;
}
</style>
