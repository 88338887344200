<template>
  <teleport v-if="isCameraVisible" to="body">
    <div class="xone-camera">
      <div
        class="xone-camera-container"
        :style="{
          width: `${size - 50}px`,
        }"
      >
        <!-- Cancel button -->
        <button class="xone-cancel" @click="isCameraVisible = false">
          Cancel
        </button>
        <!-- Camera -->
        <video
          v-if="!isNotSupported && !isVideoRecorded && !isPhotoTook"
          :style="{
            width: `${size - 50}px`,
          }"
          ref="videoElement"
          autoplay
        ></video>
        <!-- Video preview -->
        <video
          v-show="isVideoRecorded"
          ref="videoPreviewElement"
          :style="{
            width: `${size - 50}px`,
          }"
          controls
        ></video>
        <!-- Photo preview -->
        <div
          v-show="isPhotoTook"
          ref="photoPreviewElement"
          :style="{
            width: `${size - 50}px`,
          }"
        ></div>
        <!-- Not supported message -->
        <div style="padding: 50px" v-if="isNotSupported">
          CAMERA UNSUPPORTED IN YOUR BROWSER
        </div>
        <div>
          <div v-if="!isNotSupported && !isVideoRecorded && !isPhotoTook">
            <!-- Photo button -->
            <button
              v-if="!isRecording && isTypePhoto"
              class="xone-photo"
              @click="takePhoto"
            >
              <div><div></div></div>
              <br />
            </button>
            <!-- Video button -->
            <button v-if="isTypeVideo" class="xone-video" @click="recordVideo">
              <div>
                <div
                  :style="{
                    borderRadius: isRecording ? '5px' : '100%',
                  }"
                ></div>
              </div>
              <br />
            </button>
          </div>
          <div v-if="isVideoRecorded || isPhotoTook">PREVIEW</div>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script>
import { computed, inject, ref, Ref, watch } from "vue";

import { setStartCameraCallback } from "../../composables/XoneUI";

export default {
  setup() {
    const isCameraVisible = ref(false);

    /**
     * video element
     * @type {Ref<HTMLElement>}
     */
    const videoElement = ref();

    const isPhotoTook = ref(false);
    const isVideoRecorded = ref(false);

    /**
     * photo preview element
     * @type {Ref<HTMLElement>}
     */
    const photoPreviewElement = ref();

    /**
     * video preview element
     * @type {Ref<HTMLElement>}
     */
    const videoPreviewElement = ref();

    /**
     * is not supported
     * @type {Ref<boolean>}
     */
    const isNotSupported = ref(false);

    /**
     * stream
     * @type {MediaStream}
     */
    let stream;

    const isTypeVideo = ref(true);
    const isTypePhoto = ref(true);

    setStartCameraCallback((type) => {
      isTypeVideo.value = type !== "photo";
      isTypePhoto.value = type !== "video";

      isCameraVisible.value = true;
      // TODO: devolver la captura, habrá que ver cómo lo vamos a implementar para subir el fichero al servidor
    });

    /**
     * Show / Hide camera
     */
    watch(
      () => isCameraVisible.value,
      async (newValue) => {
        // reset booleans
        isNotSupported.value = false;
        isPhotoTook.value = false;
        isVideoRecorded.value = false;
        // Start camera
        if (newValue) {
          if (!navigator.mediaDevices) {
            isNotSupported.value = true;
            return;
          }
          navigator.mediaDevices.enumerateDevices().then(console.log); // TODO:  cambiar de recurso para alterar front y back camera de un dispositivo mobil
          stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: true,
          });
          videoElement.value.srcObject = stream;
        } else {
          // Stop camera
          if (stream) stream.getTracks().forEach((track) => track.stop());
        }
      }
    );

    /**
     * Window Size
     * @type {{containerWidth: Ref<number>, containerHeight: Ref<number>}}
     */
    const { containerWidth, containerHeight } = inject("containerSize");

    const size = computed(() =>
      containerWidth.value < containerHeight.value
        ? containerWidth.value
        : containerHeight.value
    );

    const takePhoto = () => {
      let canvas = document.createElement("canvas");

      canvas.attributes.height = videoElement.value.offsetHeight;
      canvas.attributes.width = videoElement.value.offsetWidth;

      let context = canvas.getContext("2d");

      context.drawImage(videoElement.value, 0, 0, canvas.width, canvas.height);

      context.canvas.toBlob((blob) => {
        blobValue = blob;

        const newImg = document.createElement("img"),
          url = URL.createObjectURL(blob);

        newImg.onload = () => URL.revokeObjectURL(url);

        newImg.src = url;
        newImg.height = videoElement.value.offsetHeight;
        newImg.width = videoElement.value.offsetWidth;

        photoPreviewElement.value.innerHTML = "";
        photoPreviewElement.value.appendChild(newImg);

        canvas = null;
        context = null;

        isVideoRecorded.value = false;
        isPhotoTook.value = true;
      });
    };

    const isRecording = ref(false);

    let videoTimeout;

    // media recorder
    let mediaRecorder;

    /**
     * video chunks
     * @type {Array}
     */
    let chunks;

    /**
     * blob  (video)
     * @type {Blob}
     */
    let blobValue;

    const recordVideo = () => {
      if (isRecording.value) {
        if (videoTimeout) clearTimeout(videoTimeout);
        mediaRecorder.stop();
      }
      if (!MediaRecorder) {
        isNotSupported.value = true;
        return;
      }
      chunks = [];

      const options = {
        mimeType: "video/webm;codecs=h264",
      };

      if (!MediaRecorder.isTypeSupported(options.mimeType))
        options.mimeType = "video/webm;codecs=vp8";

      mediaRecorder = new MediaRecorder(stream, options);

      mediaRecorder.start();

      isRecording.value = true;

      mediaRecorder.ondataavailable = (e) => chunks.push(e.data);

      mediaRecorder.onstop = (e) => {
        console.log("mediaRecorder.onstop", e);
        blobValue = new Blob(chunks, { type: "video/webm" });

        const url = URL.createObjectURL(blobValue);

        videoPreviewElement.value.onload = () => URL.revokeObjectURL(url);

        videoPreviewElement.value.src = url;
        videoPreviewElement.value.autoplay = true;

        isVideoRecorded.value = true;
        isPhotoTook.value = false;
        isRecording.value = false;
      };

      videoTimeout = setTimeout(() => mediaRecorder.stop(), 15000);
    };
    return {
      isCameraVisible,
      videoElement,
      size,
      isNotSupported,
      takePhoto,
      isRecording,
      recordVideo,
      photoPreviewElement,
      videoPreviewElement,
      isPhotoTook,
      isVideoRecorded,
      isTypeVideo,
      isTypePhoto,
    };
  },
};
</script>

<style scoped>
.xone-camera {
  display: flex;
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.xone-camera-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  border: 1px solid black;
  animation: zoomIn 0.3s;
}

.xone-video,
.xone-photo {
  align-items: center;
  margin: 20px;
  background-color: transparent;
  border: none;
  width: 60px;
  height: 60px;
  animation: fadeIn 0.3s;
}

.xone-photo > div,
.xone-video > div {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  overflow: visible;
  border-radius: 100%;
  background-color: transparent;
}

.xone-photo > div > div {
  width: 58px;
  height: 58px;
  border-radius: 100%;
  background: white;
  border: 1px solid gray;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.xone-video > div > div {
  height: 58px;
  width: 58px;
  background: red;
  border: 1px solid gray;
  transition: all 0.3s;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.xone-cancel {
  position: sticky;
  right: 5px;
  top: 5px;
  align-self: flex-end;
  margin: 10px 10px 10px 0;
  padding: 5px 10px;
  border: none;
  color: #3273dc;
  border: 1px #3273dc solid;
  border-radius: 5px;
  background-color: transparent;
  font-size: 16px;
}

button {
  outline: none;
}

video {
  animation: fadeIn 0.3s;
}
</style>