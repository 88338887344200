import { ref, Ref } from "vue";
import { XoneDataCollection } from "./appData/core/XoneDataCollection";
import { XoneDataObject } from "./appData/core/XoneDataObject";
import { XoneApplication } from "../composables/appData/core/XoneApplication";
import { generateUniqueId } from "./helperFunctions/StringHelper";

/** Class to handle appData, dataObjectsMap and breadcumb stack */
class AppDataHandler {
  /**
   * _instance
   * @type {AppDataHandler}
   */
  static _instance;

  /**
   * appData
   * @type {XoneApplication}
   */
  _appData;

  /**
   * dataObjectsMap
   * @type {Map<string, XoneDataObject>}
   */
  _dataObjectsMap = new Map();

  /**
   * breadcumbs
   * @type {Ref<Array>}
   */
  _breadcumbs = ref([]);

  constructor() {
    if (AppDataHandler._instance) return AppDataHandler._instance;
    AppDataHandler._instance = this;
  }

  /**
   * Set AppData
   * @param {XoneApplication} appData
   */
  setAppData(appData) {
    this._appData = appData;
  }

  /**
   * Get AppData
   * @returns {XoneApplication}
   */
  getAppData() {
    return this._appData;
  }

  /**
   * Get breadcumbs
   * @returns {Array<Object>}
   */
  getbreadcumbs() {
    return this._breadcumbs.value;
  }

  /**
   * Set DataObject
   * @param {string} id
   * @param {XoneDataObject} value
   */
  setDataObject(id, value) {
    this._dataObjectsMap.set(id, value);
  }

  /**
   * Get XOneDataObject
   * @param {string} id
   * @returns {XoneDataObject}
   */
  getDataObject(id) {
    return this._dataObjectsMap.get(id);
  }

  /**
   * Clear dataObjectsMap
   */
  cleardataObjectsMap() {
    this._dataObjectsMap.clear();
  }

  /**
   * Clear  Breadcumbs
   */
  clearbreadcumbs() {
    this._breadcumbs.value = [];
    this.cleardataObjectsMap();
  }

  /**
   * Clear breadcumb
   * @param {string} id
   */
  clearbreadcumb(id) {
    const breadcumb = this._breadcumbs.value.find((e) => e.id === id);
    if (!breadcumb) return;
    this._breadcumbs.value = this._breadcumbs.value.filter((e) => e.id !== id);
    this._dataObjectsMap.delete(id);
  }

  /**
   * delete last breadcumb
   */
  deleteLastBreadcumb() {
    if (this._breadcumbs.value.length === 0) return;
    const lastBreadcumb = [...this._breadcumbs.value].reverse()[0];
    this.clearbreadcumb(lastBreadcumb.id);
  }

  /**
   * Clear breadcumbs from key
   * @param {string} id
   */
  clearbreadcumbFrom(id) {
    const breadcumb = this._breadcumbs.value.find((e) => e.xoneHashId === id);
    if (!breadcumb) return;
    this._breadcumbs.value = this._breadcumbs.value.filter(
      (e) => e.xoneHashId !== id
    );
  }

  /**
   * Create new XoneDataObject and push it into dataObjectsMap Map
   * @param {string} collName
   * @param {string} [objectType]
   * @returns {Promise<string>}
   */
  async addNewXoneDataObject(collName, objectType = "Coll") {
    /**
     * Create XoneDataObject
     * @type {XoneDataCollection}
     */
    const xoneDataCollection = await this._appData.getCollection(
      // "EspecialFontSize"
      collName
    );

    if (!xoneDataCollection) return;

    /**
     * xoneDataObject
     * @type {XoneDataObject}
     */
    const xoneDataObject = await xoneDataCollection.CreateObject(true);

    return this.pushXoneDataObject(xoneDataObject, objectType);
  }

  /**
   * Push new XoneDataObject into dataObjectsMap Map
   * @param {XoneDataObject} xoneDataObject
   * @param {string} [objectType]
   * @returns {string}
   */
  pushXoneDataObject(xoneDataObject, objectType = "Coll") {
    const id = generateUniqueId();

    // Set XOneDataObject
    this.setDataObject(id, xoneDataObject);

    const name = xoneDataObject?.m_owner?.m_strName;

    if (!xoneDataObject["_XoneHashId"])
      xoneDataObject["_XoneHashId"] = Array(3).join(generateUniqueId());

    const xoneHashId = xoneDataObject["_XoneHashId"];

    // Push breadcumb
    this._breadcumbs.value.push({
      id,
      xoneHashId,
      type: objectType,
      name,
    });
    return id;
  }

  /**
   * setCookie
   * @param {string} cname cookie name
   * @param {*} cvalue cookie value
   * @param {number} exdays expiration days
   */
  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  /**
   * getCookie
   * @param {string} cname cookie name
   */
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  getCircularReplacer() {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          console.warn("Cache duplicado", key, value);
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }
}

export default new AppDataHandler();
